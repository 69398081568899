import { navigate } from 'gatsby'
import moment from 'moment'
import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import BlogEntry, { getBlogEntryLink } from '../models/BlogEntry'
import CustomerLogin from '../models/CustomerLogin'
import Entity from '../models/Entity'
import Event, {
	getBbupLink,
	getBugBountyLink,
	getEventLink,
	getWupLink,
} from '../models/Event'
import { getMdPageLinkFromEvent } from '../models/MdPage'
import Package, { getPackageLink } from '../models/Package'
import Service, { getServiceLink } from '../models/Service'
import Video, { getVideoLink } from '../models/Video'
import PromoteRibbon from './PromoteRibbon'
import { Separator } from './Separator'
import { useMeasure } from 'react-use'
import { MarkdownRenderer } from './MarkdownRenderer'
import { storeDescriptionRenderers } from '../constants/markdownRenderers'

interface Props {
	title: string
	description?: ReactNode
	mdDescription?: string
	label?: string
	logo?: string
	fitLogo?: boolean
	promote?: boolean
	asListItem?: boolean
	isLast?: boolean
	children?: ReactNode
	onClick?: () => void
}

export default function StoreItem({
	title,
	description,
	mdDescription,
	label,
	logo,
	fitLogo,
	promote,
	asListItem,
	isLast,
	children,
	onClick,
}: Props) {
	const [logoRef, { width: logoWidth }] = useMeasure<HTMLDivElement>()

	if (asListItem)
		return (
			<>
				<LogoContainer src={logo} fit={fitLogo} onClick={onClick} />
				<Info onClick={onClick}>
					<Title asListItem>{title}</Title>
					{(description || label) && (
						<SubInfo>{label && <Label asListItem>{label}</Label>}</SubInfo>
					)}
					{mdDescription && <PlainMdRenderer>{mdDescription}</PlainMdRenderer>}
				</Info>
				{children}
				{!isLast && <Separator />}
			</>
		)

	return (
		<ItemContainer className="app__store-item__card">
			<Logo
				title={title}
				src={logo}
				fit={fitLogo}
				ref={logoRef}
				style={{ height: logoWidth }}
				onClick={onClick}
			>
				{promote && <PromoteRibbon />}
			</Logo>
			<Content>
				<Title title={title} onClick={onClick}>
					{title}
				</Title>
				{description && <Description>{description}</Description>}
				{mdDescription && (
					<MarkdownRenderer components={storeDescriptionRenderers}>
						{mdDescription}
					</MarkdownRenderer>
				)}
				{label && <Label>{label}</Label>}
			</Content>
		</ItemContainer>
	)
}

const navigateToContent = (link: string) =>
	navigate(link, { state: { scrollToMain: true } })

export const propsFromBlogEntry = ({
	attributes: entry,
}: Entity<BlogEntry>): Props => ({
	title: entry.title,
	description: moment(entry.publishedAt).format('DD.MM.YYYY'),
	mdDescription: entry.mdDescription,
	logo: entry.logo?.data?.attributes.url,
	fitLogo: entry.fitLogo,
	onClick: () => {
		if (entry.externalLink) {
			window.open(entry.externalLink, '_blank', 'noopener noreferrer')
			return
		}
		navigateToContent(getBlogEntryLink(entry))
	},
})

export const propsFromVideo = ({
	attributes: video,
}: Entity<Video>): Props => ({
	title: video.title,
	description: moment(video.publishedAt).format('DD.MM.YYYY'),
	mdDescription: video.mdDescription,
	logo: video.logo?.data?.attributes.url,
	fitLogo: video.fitLogo,
	onClick: () => {
		if (video.externalLink) {
			window.open(video.externalLink, '_blank', 'noopener noreferrer')
			return
		}
		navigateToContent(getVideoLink(video))
	},
})

export const propsFromService = ({
	attributes: service,
}: Entity<Service>): Props => ({
	title: service.title,
	mdDescription: service.mdDescription,
	logo: service.logo?.data?.attributes.url,
	fitLogo: service.fitLogo,
	onClick: () => {
		if (service.externalLink) {
			window.open(service.externalLink, '_blank', 'noopener noreferrer')
			return
		}
		navigateToContent(getServiceLink(service))
	},
})

export const propsFromCustomerLogins = ({
	attributes: login,
}: Entity<CustomerLogin>): Props => ({
	title: login.title,
	mdDescription: login.mdDescription,
	logo: login.logo.data.attributes.url,
	fitLogo: login.fitLogo,
	onClick: () => window.open(login.link, '_blank', 'noopener noreferrer'),
})

export const propsFromEvent = ({
	attributes: event,
}: Entity<Event>): Props => ({
	title: event.name,
	description:
		event.showEventEndDate && event.eventEndDate
			? `Until ${moment(event.eventEndDate).format('DD.MM.YYYY HH:mm')}`
			: undefined,
	mdDescription: event.mdDescription,
	logo: event.logo?.data?.attributes.url,
	fitLogo: event.fitLogo,
	onClick: () => {
		if (event.externalLink) {
			window.open(event.externalLink, '_blank', 'noopener noreferrer')
			return
		}
		if (event.mdPageLink) {
			navigateToContent(getMdPageLinkFromEvent(event))
			return
		}
		navigateToContent(getEventLink(event))
	},
})

export const propsFromWup = ({ attributes: event }: Entity<Event>): Props => {
	const endDate = moment(event.eventEndDate)
	let qualifier = 'Ended'
	if (endDate.isAfter()) qualifier = 'Ending'

	return {
		title: event.name,
		description:
			event.showEventEndDate && event.eventEndDate
				? `${qualifier} ${endDate.format('DD.MM.YYYY HH:mm')}`
				: undefined,
		mdDescription: event.mdDescription,
		logo: event.logo?.data?.attributes.url,
		fitLogo: event.fitLogo,
		onClick: () => navigateToContent(getWupLink(event)),
	}
}

export const propsFromBugBounty = ({
	attributes: event,
}: Entity<Event>): Props => ({
	title: event.name,
	description:
		event.showEventEndDate && event.eventEndDate
			? `Until ${moment(event.eventEndDate).format('DD.MM.YYYY HH:mm')}`
			: undefined,
	mdDescription: event.mdDescription,
	logo: event.logo?.data?.attributes.url,
	fitLogo: event.fitLogo,
	onClick: () => {
		if (event.externalLink) {
			window.open(event.externalLink, '_blank', 'noopener noreferrer')
			return
		}
		if (event.mdPageLink) {
			navigateToContent(getMdPageLinkFromEvent(event))
			return
		}
		navigateToContent(getBugBountyLink(event))
	},
})

export const propsFromBbup = ({ attributes: event }: Entity<Event>): Props => ({
	title: event.name,
	description:
		event.showEventEndDate && event.eventEndDate
			? `Ended ${moment(event.eventEndDate).format('DD.MM.YYYY HH:mm')}`
			: undefined,
	mdDescription: event.mdDescription,
	logo: event.logo?.data?.attributes.url,
	fitLogo: event.fitLogo,
	onClick: () => navigateToContent(getBbupLink(event)),
})

export const propsFromPackage = ({
	attributes: pkg,
}: Entity<Package>): Props => ({
	title: pkg.name,
	mdDescription: pkg.mdDescription,
	label: pkg.price ? `${pkg.currency} ${pkg.price}.-` : 'FREE',
	logo: pkg.logo?.data?.attributes.url,
	fitLogo: pkg.fitLogo,
	promote: pkg.promote,
	onClick: () => navigateToContent(getPackageLink(pkg)),
})

const ItemContainer = styled.div`
	position: relative;
`

const LogoContainer = styled.div`
	margin: 0;
	padding: 0;
	height: 90px;
	width: 90px;
	justify-self: center;
	background: #fff;
	cursor: pointer;
	${({ src, fit }: LogoProps) =>
		src
			? css`
					background-image: url('${src}');
					background-position: center;
					background-size: ${fit ? 'cover' : 'contain'};
					background-repeat: no-repeat;
			  `
			: null}
`

interface LogoProps {
	src?: string
	fit?: boolean
}

const Logo = styled.div`
	position: relative;
	background: #fff;
	cursor: pointer;
	${({ src, fit }: LogoProps) =>
		src
			? css`
					background-image: url('${src}');
					background-position: center;
					background-size: ${fit ? 'cover' : 'contain'};
					background-repeat: no-repeat;
			  `
			: null}
`

const Content = styled.div`
	flex: 1;
	min-height: 0;
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	width: 100%;
	margin-top: 1em;
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	max-width: 100%;
	overflow: hidden;
	gap: 5px;
	max-width: 100%;
	cursor: pointer;
`

const SubInfo = styled.div`
	display: flex;
	gap: 5px;
`

interface ListProps {
	asListItem?: boolean
}

const Title = styled.div<ListProps>`
	max-width: 100%;
	font-size: 26px;
	font-weight: 600;
	line-height: 31.2px;
	font-family: Heading;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media (max-width: 600px) {
		font-size: 20px;
		line-height: 24px;
	}
	${({ onClick }) =>
		onClick &&
		css`
			cursor: pointer;
		`}
`

const Description = styled.div<ListProps>`
	font-weight: 300;
	${({ asListItem }) =>
		asListItem
			? css``
			: css`
					display: flex;
					flex-direction: column;
					position: relative;
					flex: 1;
			  `}
`

const Label = styled.div<ListProps>`
	font-weight: 400;
	${({ asListItem }) =>
		!asListItem &&
		css`
			margin-top: 0.5em;
		`}
`

const PlainMdRenderer = styled.div`
	overflow: hidden;
	max-width: 100%;
	max-height: 2em;
	text-overflow: ellipsis;
	display: -webkit-box !important;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
`
