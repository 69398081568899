import Media from './Media'
import Payload from './Payload'
import Tenant from './Tenant'

export default interface Package<TLogo = Payload<Media>> {
	name: string
	displayName: string
	description: string
	currency?: string
	price?: number
	usage?: string
	promote?: boolean
	storeLink?: string
	mdDescription?: string
	details?: string
	organization?: string
	logo?: TLogo
	fitLogo?: boolean
	urlPath: string
	tenant?: Tenant
}

export const getPackageLink = (pkg: Package<unknown>) => `/shop/${pkg.urlPath}`
