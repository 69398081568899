import Media from './Media'
import Package from './Package'

export default interface OrderItem {
	package: Package<Media>
	tokenId?: number
	token?: string
	shareEmail?: string
	sharedSuccessfully?: boolean
}

export const buildRedeemLink = (orderItem: OrderItem) => {
	let basePath = orderItem.package.tenant?.redeemBasePath
	if (!basePath) return undefined
	if (!basePath.endsWith('/')) basePath = `${basePath}/`

	return `${basePath}${orderItem.token}`
}
