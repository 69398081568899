import Media from './Media'
import Payload from './Payload'

export default interface Event {
	name: string
	markdown?: string
	mdDescription?: string
	externalLink?: string
	logo?: Payload<Media>
	mdPageLink?: string
	writeUp?: string
	showWriteUp?: boolean
	eventEndDate?: string
	showEventEndDate?: boolean
	urlPath: string
	fitLogo?: boolean
}

export const getEventLink = (event: Event) => `/events/${event.urlPath}`

export const getWupLink = (event: Event) => `/events-archive/${event.urlPath}`

export const getBugBountyLink = (event: Event) =>
	`/bug-bounties/${event.urlPath}`

export const getBbupLink = (event: Event) =>
	`/bug-bounties-archive/${event.urlPath}`
