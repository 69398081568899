import { API_URI } from '.'
import Order from '../models/Order'
import OrderItem from '../models/OrderItem'
import { getHeaders } from './auth'

export async function getOrders(
	orderId: string,
	signature: string
): Promise<Order> {
	const response = await fetch(
		`${API_URI}/tokens/licence` +
			`?orderId=${orderId}` +
			`&signature=${signature}`,
		{
			method: 'GET',
			headers: getHeaders(),
		}
	)

	if (!response.ok) return { meta: {}, items: [] }

	return await response.json()
}

export async function postLinkSharing(
	orderId: string,
	signature: string,
	items: OrderItem[]
): Promise<OrderItem[] | undefined> {
	const response = await fetch(
		`${API_URI}/tokens/linkSharing` +
			`?orderId=${orderId}` +
			`&signature=${signature}`,
		{
			method: 'POST',
			headers: getHeaders(),
			body: JSON.stringify(items),
		}
	)

	if (!response.ok) return undefined

	return await response.json()
}
