import React, { ChangeEvent, CSSProperties } from 'react'
import styled, { css } from 'styled-components/macro'
import { InputBoxShadow } from '../styles/form'
import FormField from './FormField'

export interface TextInputProps {
	value?: string | number
	onChange?: (value: string) => void
	label?: string
	type?: 'text' | 'password' | 'number' | 'textarea'
	className?: string
	placeholder?: string
	disabled?: boolean
}

export default function TextInput({
	value,
	onChange,
	label,
	className,
	placeholder,
	disabled,
	type = 'text',
}: TextInputProps) {
	let InputControl: typeof Input | typeof TextArea = Input
	const style: CSSProperties = { minWidth: '100%', maxWidth: '100%' }
	if (type === 'textarea') {
		InputControl = TextArea
		style.height = '10em'
	}

	return (
		<FormField label={label}>
			<InputControl
				value={value || ''}
				type={type}
				disabled={disabled}
				onChange={(
					event:
						| ChangeEvent<HTMLInputElement>
						| ChangeEvent<HTMLTextAreaElement>
				) => onChange && onChange(event.currentTarget.value)}
				className={className}
				placeholder={placeholder}
				style={style}
			/>
		</FormField>
	)
}

const Input = styled.input`
	border: 1px solid #ccc;
	border-radius: 3px;
	font-size: 1.2em;
	padding: 0.5em 1em;
	outline: none;
	transition: box-shadow 0.1s ease-out;

	${({ disabled }: { disabled?: boolean }) =>
		disabled &&
		css`
			background: #eee;
		`}
	:hover, :focus {
		${InputBoxShadow}
	}
`

const TextArea = Input.withComponent('textarea')
