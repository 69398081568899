import OrderItem from './OrderItem'

export default interface Order {
	meta: {
		[key: string]: string | null | undefined
	}
	items: OrderItem[]
}

export const createOrder = () => ({ meta: {}, items: [] })
