import { graphql, navigate, useStaticQuery } from 'gatsby'
import React, { useState } from 'react'
import { FaRegFileExcel } from 'react-icons/fa'
import { FiMail, FiShare2 } from 'react-icons/fi'
import {
	MdOutlineMarkEmailRead,
	MdOutlineReportGmailerrorred,
} from 'react-icons/md'
import ReactModal from 'react-modal'
import { useAsync, useSearchParam, useTimeoutFn } from 'react-use'
import styled, { css, useTheme } from 'styled-components/macro'
import { getGlobalSettings } from '../apiClients/global-settings'
import { getOrders as getOrder, postLinkSharing } from '../apiClients/orders'
import Card from '../components/Card'
import FlatButton from '../components/FlatButton'
import Layout from '../components/Layout'
import Link from '../components/Link'
import { MarkdownCard, MarkdownRenderer } from '../components/MarkdownRenderer'
import { Separator } from '../components/Separator'
import Spinner from '../components/Spinner'
import StoreItem from '../components/StoreItem'
import TextInput from '../components/TextInput'
import ArrayPayload from '../models/ArrayPayload'
import GlobalSetting, { SettingKey } from '../models/GlobalSetting'
import Order, { createOrder } from '../models/Order'
import OrderItem, { buildRedeemLink } from '../models/OrderItem'
import { getPackageLink } from '../models/Package'
import { defaultTheme } from '../styles/defaultTheme'
import { download } from '../utils/download'

interface PageQueryResult {
	settings: {
		nodes: ArrayPayload<GlobalSetting>[]
	}
}

export default function OrderPage() {
	const {
		settings: { nodes: settings },
	} = useStaticQuery<PageQueryResult>(graphql`
		query {
			settings: allStrapiGlobalsettings {
				nodes {
					data {
						id
						attributes {
							key
							value
						}
					}
				}
			}
		}
	`)

	const [loading, setLoading] = useState(false)
	const [globalSettings, setGlobalSettings] = useState<GlobalSetting[]>([])
	const [{ meta, items }, setOrder] = useState<Order>(createOrder())
	const [copiedCode, setCopiedCode] = useState<string | null>(null)
	const [shownCode, setShownCode] = useState<string | null>(null)
	const [sharingEnabled, setSharingEnabled] = useState(false)
	const [sharePending, setSharePending] = useState(false)
	const [sharingError, setSharingError] = useState<string | null>(null)

	const orderId = useSearchParam('orderId')
	const signature = useSearchParam('signature')

	useAsync(async () => {
		if (!orderId || !signature) return
		setLoading(true)
		const globalSettingsPromise = getGlobalSettings()
		const orderPromise = getOrder(orderId, encodeURIComponent(signature))
		setGlobalSettings((await globalSettingsPromise).data.map(g => g.attributes))
		setOrder(await orderPromise)
		setLoading(false)
	}, [orderId, signature])

	useTimeoutFn(
		() => {
			setCopiedCode(null)
		},
		copiedCode ? 2000 : undefined
	)

	const downloadAll = () => {
		let allCodes = 'URL,Access Code,Redeem Link\n'
		allCodes += items
			?.filter(item => !!item.token)
			.map(
				item =>
					`${item.package.tenant?.url ?? ''},${item.token},${
						buildRedeemLink(item) ?? ''
					}`
			)
			.reduce((a, b) => `${a}\n${b}`)

		download(`codes_${orderId}.csv`, allCodes ?? 'no codes found')
	}

	const downloadOne = (orderItem: OrderItem, index: number) => {
		let content = 'URL, Access Code, Redeem Link\n'
		content += `${orderItem.package.tenant?.url ?? ''},${
			orderItem.token ?? '-'
		},${buildRedeemLink(orderItem) ?? ''}`

		download(`code_${index + 1}_${orderId}.csv`, content)
	}

	const copyCode = async (token?: string) => {
		if (!token) return
		await navigator.clipboard.writeText(token)
		setCopiedCode(token)
	}

	const redeemOrderItem = async (orderItem?: OrderItem) => {
		if (orderItem?.package.tenant?.redeemBasePath) return
		if (!orderItem?.token) return
		setShownCode(orderItem.token)
	}

	const updateShareEmail = (orderItem: OrderItem, shareEmail: string) => {
		const orderItems = items.map(item => {
			if (item === orderItem) return { ...item, shareEmail }
			return item
		})
		setOrder(prevOrder => ({ ...prevOrder, items: orderItems }))
	}

	const sendSharingLinks = async () => {
		if (!orderId || !signature) return
		setSharePending(true)
		const updatedItems = await postLinkSharing(orderId, signature, items)
		if (updatedItems) {
			setOrder(prevOrder => ({ ...prevOrder, items: updatedItems }))
			setSharingError(null)
		} else {
			setSharingError('Could not send email. Try again later!')
		}
		setSharingEnabled(false)
		setSharePending(false)
	}

	const enableSharing = () => {
		const resettedItems = items.map(item => {
			if (item.sharedSuccessfully) {
				delete item.sharedSuccessfully
				delete item.shareEmail
			}
			return item
		})
		setOrder(prevOrder => ({ ...prevOrder, items: resettedItems }))
		setSharingEnabled(true)
	}

	let orderConfirmation = globalSettings.find(
		s => s.key === SettingKey.ORDER_CONFIRMATION
	)?.value
	if (orderConfirmation)
		orderConfirmation = fillTemplateVars(orderConfirmation, meta)

	let redeemAccessCode = globalSettings.find(
		s => s.key === SettingKey.REDEEM_ACCESS_CODE
	)?.value
	if (redeemAccessCode)
		redeemAccessCode = fillTemplateVars(redeemAccessCode, meta)

	const supportEmail = settings
		.flatMap(s => s.data)
		.find(s => s.attributes.key === SettingKey.SUPPORT_EMAIL)?.attributes.value

	return (
		<Layout center maxWidth>
			<ReactModal
				isOpen={!!shownCode}
				onRequestClose={() => setShownCode(null)}
				style={{
					content: {
						margin: '10px auto',
						borderRadius: 2,
						maxWidth: 800,
					},
					overlay: {
						zIndex: 1,
					},
				}}
				shouldCloseOnEsc
				shouldCloseOnOverlayClick
			>
				<ModalContent>
					{redeemAccessCode && (
						<MarkdownContainer>
							<MarkdownRenderer noBackground>
								{redeemAccessCode}
							</MarkdownRenderer>
						</MarkdownContainer>
					)}
					<BigCode>{shownCode}</BigCode>
					<CloseButton onClick={() => setShownCode(null)}>Close</CloseButton>
				</ModalContent>
			</ReactModal>
			<MarkdownCard>
				{orderConfirmation && (
					<MarkdownContainer>
						<MarkdownRenderer noBackground>
							{orderConfirmation}
						</MarkdownRenderer>
					</MarkdownContainer>
				)}
				{items.length > 1 && items.filter(o => !!o.token).length !== 0 && (
					<ActionBar>
						<ActionButton onClick={downloadAll}>
							<XlsIcon />
							<div>Download All Access Codes</div>
						</ActionButton>
						{sharingEnabled ? (
							<ActionButton onClick={sendSharingLinks} loading={sharePending}>
								<EmailIcon />
								<div>Send Invitations</div>
							</ActionButton>
						) : (
							<ActionButton onClick={enableSharing}>
								<ShareIcon />
								<div>Share</div>
							</ActionButton>
						)}
					</ActionBar>
				)}
				{sharingError && <Error>{sharingError}</Error>}
				<Separator />
				{!loading && items.length === 0 ? (
					<MessageSection>No items</MessageSection>
				) : !loading ? (
					<Grid>
						{items.map((orderItem, i) => {
							const redeemLink = buildRedeemLink(orderItem)
							const shortCode = shortenCode(orderItem.token)
							return (
								<>
									<StoreItem
										key={i}
										logo={orderItem.package.logo?.url}
										fitLogo={orderItem.package.fitLogo}
										title={orderItem.package.displayName}
										description={removeScheme(orderItem.package.tenant?.url)}
										onClick={() => navigate(getPackageLink(orderItem.package))}
										isLast={items.length < i + 2}
										asListItem
									>
										<Table buttonOnly={!!redeemLink}>
											{!redeemLink && (
												<CodeSection>
													{shortCode ?? (
														<div>
															<div>No Access Code left.</div>
															<div>
																please contact{' '}
																<Link
																	href={`mailto:${supportEmail}`}
																	target="_blank"
																	rel="noopener noreferrer"
																>
																	{supportEmail}
																</Link>
															</div>
														</div>
													)}
												</CodeSection>
											)}
											{orderItem.token && (
												<>
													{!redeemLink && (
														<>
															<CopyLink
																onClick={() => copyCode(orderItem.token)}
															>
																{copiedCode === orderItem.token
																	? 'Copied!'
																	: 'Copy'}
															</CopyLink>

															<DownloadLink
																onClick={() => downloadOne(orderItem, i)}
															>
																Download
															</DownloadLink>
														</>
													)}
													<ExternalRedeem
														href={redeemLink}
														target="_blank"
														rel="noopener noreferrer"
													>
														<FlatButton
															onClick={() => redeemOrderItem(orderItem)}
														>
															Join Training
														</FlatButton>
													</ExternalRedeem>
												</>
											)}
										</Table>
										{sharingEnabled && !!shortCode && (
											<Row>
												<TextInput
													label="Send invitation to"
													placeholder="Email"
													value={orderItem.shareEmail}
													onChange={email => updateShareEmail(orderItem, email)}
												/>
											</Row>
										)}
										{typeof orderItem.sharedSuccessfully === 'boolean' && (
											<Row>
												{orderItem.sharedSuccessfully === true ? (
													<MailShareStatus>
														<MdOutlineMarkEmailRead /> Email sent successfully
														to <b>{orderItem.shareEmail}</b>
													</MailShareStatus>
												) : orderItem.sharedSuccessfully === false ? (
													<MailShareStatus failed>
														<MdOutlineReportGmailerrorred /> Failed to send
														email to <b>{orderItem.shareEmail}</b>
													</MailShareStatus>
												) : null}
											</Row>
										)}
									</StoreItem>
								</>
							)
						})}
					</Grid>
				) : (
					<MessageSection>
						<Spinner /> Loading order...
					</MessageSection>
				)}
			</MarkdownCard>
		</Layout>
	)
}

const fillTemplateVars = (template: string, meta: Order['meta']) => {
	const placeholders = template.match(/\{\{[a-zA-Z0-9_]*\}\}/g)
	placeholders?.forEach(placeholder => {
		const varName = placeholder.replace('{{', '').replace('}}', '').trim()
		template = template.replace(placeholder, meta[varName] ?? '')
	})

	return template
}

const removeScheme = (url?: string) => url?.replace(/^https?:\/\//, '')

const shortenCode = (url?: string) => {
	if (!url) return url
	if (url.length <= 25) return url
	return `${url.substring(0, 25)}...`
}

const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	align-content: flex-end;
`

const CloseButton = styled(FlatButton)`
	flex: 1;
	align-self: center;
	margin-top: 20px;
`

const ActionButton = styled(FlatButton)`
	margin-bottom: 20px;
`

const XlsIcon = styled(FaRegFileExcel)`
	margin-right: 5px;
`

const EmailIcon = XlsIcon.withComponent(FiMail)

const ShareIcon = XlsIcon.withComponent(FiShare2)

const Grid = styled.div`
	display: grid;
	grid-template-columns: auto 1fr auto;
	gap: 20px 15px;
	align-items: center;
	width: 100%;
	padding-top: 20px;
	@media (max-width: 700px) {
		grid-template-columns: auto 1fr;
	}
`

const ActionBar = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	@media (max-width: 700px) {
		flex-direction: column;
	}
`

const Row = styled.div`
	grid-column: 1 / -1;
`

const MailShareStatus = styled.div<{ failed?: boolean }>`
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 18px;
	color: ${p => (p.failed ? '#ff0000' : p.theme.linkColor)};
`

const Table = styled.div<{ buttonOnly?: boolean }>`
	display: grid;
	gap: 10px 5px;
	grid-template-columns: 1fr 1fr;
	${p =>
		p.buttonOnly
			? css`
					grid-template-areas: 'button button';
			  `
			: css`
					grid-template-areas:
						'code   code'
						'copy   down'
						'button button';
			  `}
	@media (max-width: 700px) {
		grid-column: 1 / -1;
	}
`

const CodeSection = styled.div`
	grid-area: code;
	display: flex;
	justify-content: space-between;
	gap: 5px;
`

const A = styled.span`
	justify-self: flex-start;
	color: ${p => p.theme.linkColor};
	cursor: pointer;
	:hover {
		text-decoration: underline;
	}
`

const CopyLink = styled(A)`
	grid-area: copy;
`

const DownloadLink = styled(A)`
	grid-area: down;
`

const ExternalRedeem = styled.a`
	grid-area: button;
`

const BigCode = styled.pre`
	word-wrap: break-word;
	white-space: pre-line;
	font-size: 18px;
	line-height: 1.3em;
`

const MarkdownContainer = styled.div`
	width: 100%;
	margin-bottom: 20px;
`

const MessageSection = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 10px;
	margin: 20px 0;
`

const Error = styled.div`
	color: #ff0000;
	grid-column: 1 / -1;
	text-align: end;
`
