import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

interface Props {
	label?: string
	children: ReactNode
}

const FormField = ({ label, children }: Props) => (
	<Container hasLabel={!!label}>
		{label && <Label>{label}</Label>}
		{children}
	</Container>
)

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 0;
	${({ hasLabel }: { hasLabel?: boolean }) =>
		hasLabel
			? css`
					margin-bottom: 10px;
			  `
			: null}
`

const Label = styled.label`
	margin-bottom: 5px;
`

export default FormField
